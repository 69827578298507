<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_operator_new_application') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(status)="data">
                     {{ getStatus(data.item.status) }}
                    </template>
                    <template v-slot:cell(address)="data">
                     {{ getAddress(data.item) }}
                    </template>
                    <template v-slot:cell(pump_address)="data">
                     {{ getPumpAddress(data.item) }}
                    </template>
                    <template v-slot:cell(payment_status)="data">
                     {{ getPaymentStatus(data.item.payment_status) }}
                    </template>
                    <template v-slot:cell(created_date)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-1 title="View Application"  @click="setRowId(data.item, 1, $t('pump_install.application_details'))"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.survey_id === null" v-b-modal.modal-1 title="Add Survey" @click="setRowId(data.item, 2, $t('pump_install.add_survey_report'))"><i class="fas fa-plus"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_statis" v-if="data.item.survey_id !== null" v-b-modal.modal-1 title="Survey Report" @click="setRowId(data.item, 3, $t('pump_install.survey_report'))"><i class="ri-file-chart-line"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-1" size="lg" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <PumpOptApplicationDetails :id="testId" :key="testId" v-if="modalType === 1"/>
            <AddSurveyReport :id="testId" :key="testId" v-if="modalType === 2"/>
            <SurveyReport :id="testId" :key="testId" v-if="modalType === 3"/>
          </p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptApplicationListSurvey, pumpOptApplicationApprove, pumpOptAppReceive } from '../../api/routes'
import PumpOptApplicationDetails from '../PumpOptApplicationDetails'
import AddSurveyReport from './AddSurveyReport'
import SurveyReport from './SurveyReport'
export default {
  name: 'UiDataTable',
  components: {
    PumpOptApplicationDetails,
    AddSurveyReport,
    SurveyReport
  },
  data () {
    return {
      applications: [],
      modalType: -1,
      modalTitle: '',
      loggedInUserId: this.$store.state.Auth.authUser.user_id,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        receiver_id: this.$store.state.Auth.authUser.user_id
      },
      testId: 0,
      rows: [],
      ifApply: false,
      irriUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.ExternalUserIrrigation.pumpOptApplicationLists
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('pump_install.name'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.address'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.pump_address'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.payment_status'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
        { key: 'index' },
          { key: 'name_bn' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
         { key: 'index' },
          { key: 'name' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    setRowId (item, type, title) {
      this.modalType = type
      this.modalTitle = title
      this.testId = item.id
    },
    receive (item) {
      this.receiveApplication(item)
    },
    approve (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${pumpOptApplicationApprove}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('Complain/updateComplainChainStatus', item)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    async receiveApplication (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.deleteData(irriSchemeServiceBaseUrl, `${pumpOptAppReceive}/${item.id}`)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Application Receive successfully',
          color: '#D6E09B'
        })
      }
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        receiver_id: this.$store.state.Auth.authUser.user_id
      })
      RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationListSurvey, params).then(response => {
        if (response.success) {
          this.applications = response.data.data
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getPaymentStatus (payStatus) {
      if (payStatus === 0) {
        return this.$t('externalUserIrrigation.unpaid')
      } else {
        return this.$t('pump_install.paid')
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.approve')
      } else if (status === 4) {
        return this.$t('pump_install.reject')
      } else if (status === 4) {
        return this.$t('pump_install.pending')
      } else if (status === 5) {
        return this.$t('pump_install.send')
      } else if (status === 6) {
        return this.$t('pump_install.send_for_survey')
      } else if (status === 7) {
        return this.$t('pump_install.receive')
      } else if (status === 8) {
        return this.$t('pump_install.survey_submitted')
      }
    },
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    }
  }
}
</script>
