<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row style="margin-top:20px;">
                    <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.survey') + ' ' + $t('pump_install.notel') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <div>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{ $t('user.namel') }}</b-th>
                                            <b-th>{{ $t('pump_install.survey_date') }}</b-th>
                                            <b-th>{{ $t('pump_install.notel') }}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(survey,index) in surveys" :key="index">
                                            <b-td>{{ survey.name }}</b-td>
                                            <b-td>{{ survey.survey_date }}</b-td>
                                            <b-td>{{ ($i18n == 'bn')? survey.note_bn : survey.note }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl, irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptAppSendSurveyNote, currentOfficeUser } from '../../api/routes'

export default {
  name: 'SurveySendNote',
  props: ['id'],
  created () {
    this.loadData()
    this.getUsers()
  },
  data () {
    return {
      loading: false,
      approvals: '',
      surveys: '',
      users: []
    }
  },
  methods: {
    async getUsers () {
      this.loading = true
      await RestApi.getData(authServiceBaseUrl, currentOfficeUser + '/' + this.$store.state.Auth.authUser.office_id)
      .then(response => {
        this.users = response.data
        this.loading = false
      })
    },
    loadData () {
      this.loading = true
      RestApi.getData(irriSchemeServiceBaseUrl, pumpOptAppSendSurveyNote + '/' + this.$props.id).then(response => {
        if (response.success) {
          this.approvals = this.approvalFormatData(response.approvals)
          this.surveys = this.surveyFormatData(response.surveys)
        }
        this.loading = false
      })
    },
    approvalFormatData (data) {
      try {
          let tmpData = {}
          const listData = data.map(item => {
              tmpData = this.users.find(user => user.value === item.sender_id)
              const nameData = { name: tmpData.text }
              return Object.assign({}, item, nameData)
          })
          return listData
      } catch (error) {
          if (error) { }
          return []
      }
    },
    surveyFormatData (data) {
      try {
          let tmpData = {}
          const listData = data.map(item => {
              tmpData = this.users.find(user => user.value === item.user_id)
              const nameData = { name: tmpData.text }
              return Object.assign({}, item, nameData)
          })
          return listData
      } catch (error) {
          if (error) { }
          return []
      }
    }
  }
}
</script>
