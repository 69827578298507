<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" enctype="multipart/form-data">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Survey Date" vid="survey_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="survey_date"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('pump_install.survey_date')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      class="date-picker"
                                      v-model="application.survey_date"
                                      placeholder="Select Date"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="note" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="note"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('pump_install.note')}} <span class="text-danger">*</span>
                              </template>
                              <b-textarea
                              id="note"
                              v-model="application.note"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Note (bn)" vid="note_bn">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              :label="$t('pump_install.note_bn')"
                              label-for="note_bn"
                              slot-scope="{ valid, errors }"
                            >
                              <b-textarea
                              id="note_bn"
                              v-model="application.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpOptAppAddSurvey } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      application: {
        pump_opt_apps_id: this.$props.id,
        user_id: this.$store.state.Auth.authUser.user_id,
        survey_date: '',
        note: '',
        note_bn: ''
      }
    }
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptAppAddSurvey, this.application)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
